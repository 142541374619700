body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #3d6387;
  color: #fff;
}

img {
  display: block;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


#root {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: #3d6387;
  word-break: keep-all;
}

@font-face {
  font-family: "Hanna";
  src: url("./fonts/BMHANNA.ttf");
}
@font-face {
  font-family: "HannaAir";
  src: url("./fonts/BMHANNAAir.ttf");
}

@font-face {
  font-family: "BMHANNA";
  src: url("./fonts/BMHANNAPro.ttf");
}

